@use '@angular/material' as mat;

@include mat.core();

// Define a dark theme
$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: mat.$blue-palette,
  ),
));

// Define a light theme
$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$blue-palette,
  ),
));

html {
  @include mat.all-component-typographies($dark-theme);
  @include mat.all-component-densities($dark-theme);

  // Apply the dark theme by default
  @include mat.all-component-colors($dark-theme);
  
  // Apply the light theme colors only when the user prefers light themes
  &[color-scheme=light] {
    @include mat.all-component-colors($light-theme);
  }
}

.mat-typography  {
  h1, h2, h3, h4, h5, h6 {
    @apply tw-mb-0;
  }
}

body {
  .cdk-overlay-container {
    z-index: 10000; //to fix being hidden behind primeng dialog
  }
}

:root {
  // fix material tokens
  .mat-accent.mat-mdc-checkbox {
    --mat-checkbox-label-text-color: var(--mat-app-on-surface) !important;
  }
}